<template>
  <div class="shared-table-container">
    <div class="flex-table asset-table history-table">
      <div class="flex-table-head asset-table-head">
        <div class="flex-header-section">
          <div class="flex-header result-data-id">
            <span>Object ID</span>
          </div>
          <div class="flex-header">
            <span>Object Type</span>
          </div>
          <div class="flex-header">
            <span>Event</span>
          </div>
          <div class="flex-header">
            <span>Diff</span>
          </div>
          <div class="flex-header">
            <span>Who?</span>
          </div>
          <div class="flex-header">
            <span>Date</span>
          </div>
        </div>
      </div>
      <div class="flex-table-body scroll-content">
        <loader v-if="searchIsActive" />
        <div
          v-if="!searchIsActive && searchTotal == 0"
          class="search_result flex-table-row"
        >
          <div class="flex-table-cell" />
          <div class="flex-table-cell no-results">
            No Results
          </div>
        </div>
        <search-result
          v-for="result in searchResults"
          :id="'asset_result' + result.id"
          :key="result.id"
          :result="result"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import SearchResult from './SearchResult'
import Loader from '../../components/assets/shared/Loader'
export default {
  components: {
    SearchResult,
    Loader
  },
  data () {
    return {
      search_limit: 5000, // number of milliseconds to allow subsequent queries
      last_search: (new Date()).getTime()
    }
  },
  mutations: mapMutations([
    'setTopLevelAsset'
  ]),
  computed: {
    ...mapGetters([
      'searchResults',
      'searchTotal',
      'searchIsActive'
    ])
  },
  mounted () {
    let t = this.getTableElement()
    if (t) {
      t.addEventListener('scroll', this.scrolling)
    }
  },
  destroyed () {
    let t = this.getTableElement()
    if (t) {
      t.removeEventListener('scroll', this.scrolling)
    }
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'searching'
    ]),
    ...mapMutations([
      'resetSearchResults',
      'incrementSearchPage'
    ]),
    getTableElement () {
      let table = document.querySelectorAll('.asset-table')[0]
      return table
    },
    scrolling () {
      let tbody = document.getElementsByClassName('scroll-content')[0]
      let table = this.getTableElement()
      if (Object.keys(this.searchResults).length < this.searchTotal) {
        if (table.scrollTop + tbody.clientHeight === tbody.scrollHeight) { // WORKS IN FF, CHROME, SAFARI
          if ((this.last_search + this.search_limit) < (new Date()).getTime()) {
            this.incrementSearchPage()
            this.searching()
            this.last_search = (new Date()).getTime()
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-item {
  display: flex
}
.flex-search-input {
  flex: 7;
}
.search-row-section {
  margin-bottom: 1.5rem;
  z-index: 1000;
}
.flex-header-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  position: sticky;
  top: 0;
  background-color: $white-1;
  z-index: 99999;
}
.flex-header {
  line-height: 2.5rem;
  &:last-child {
    border: 0;
  }
  span {
    font-weight: bold;
    padding-left: 1rem;
    text-align: left;
    flex-wrap: wrap;
  }
}
.result-data {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow: hidden;
  padding: 1rem;
  &.result-data-id {
    text-align: center;
    flex-wrap: nowrap;
  }
}
.result-data-id {
  min-width: 4rem;
}
.shared-table-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  border: solid 1px $grey-3;
  border-radius: 3px;
  font-size: .875rem;
  .flex-table {
    position: relative;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    .flex-table-head {
      position: sticky;
      top: 0;
      display: flex;
      border-bottom: solid 1px $grey-3;
      z-index: 200;
    }
    .flex-table-body {
      height: 70vh;
    }
    .flex-table-row {
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      flex-wrap: wrap;
      border-bottom: solid 1px $grey-3;
      &:nth-child(even) {
        background-color: $white-1;
      }
      .flex-header, .flex-table-cell {
        border-right: solid 1px $grey-3;
        padding: 0;
        margin: 0;
        &:last-child {
          border: 0;
        }
      }
      .flex-table-cell {
        @media only screen and (max-width: 800px) {
          display: block;
        }
      }
    }
  }
}
.no-results {
  text-align: center;
}
.diff-section {
  width: 100%;
}
.history-table {
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(4), .flex-table-cell:nth-child(4) { display: flex; flex: 6; overflow: hidden;}
  .flex-header:nth-child(5), .flex-table-cell:nth-child(5) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(6), .flex-table-cell:nth-child(6) { display: flex; flex: 3; overflow: hidden;}
}
.diff-table {
  overflow: hidden;
  height: auto;
  .flex-header-section, .flex-table-body, .flex-table-row {
    background: transparent;
  }
  .flex-table-body {
    height: auto !important;
  }
  .flex-table-row {
    &:last-child {
      border: 0;
    }
    &:nth-child(even) {
      background: transparent !important;
    }
  }
  .flex-header:nth-child(1), .flex-table-cell:nth-child(1) { display: flex; flex: 1; overflow: hidden;}
  .flex-header:nth-child(2), .flex-table-cell:nth-child(2) { display: flex; flex: 2; overflow: hidden;}
  .flex-header:nth-child(3), .flex-table-cell:nth-child(3) { display: flex; flex: 2; overflow: hidden;}
}
</style>
