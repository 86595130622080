<template>
  <div
    v-if="ready()"
    class="history-search"
  >
    <nav-container />
    <div class="inner-content">
      <div class="title">
        History
      </div>
      <div class="row is-collapse-child action-row">
        <search-input />
      </div>
      <div>
        <search-results />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import railsRoutes from '../../store/rails-routes'
import SearchResults from './SearchResults.vue'
import SearchInput from '../assets/shared/SearchInput.vue'
import searchKeys from '../../mixins/search-keys'
import NavContainer from '../shared/NavContainer'
export default {
  components: {
    SearchInput,
    SearchResults,
    NavContainer
  },
  mixins: [searchKeys],
  data () {
    return {
      objectTypes: [
        'V3Flashcard',
        'Answer',
        'Attachment',
        'Topic',
        'Grouping',
        'AssetAssociation',
        'ProductAssociation',
        'NavigationGroup',
        'NavigationItem',
        'Deck',
        'Card'
      ],
      eventTypes: [
        'create',
        'update'
      ]
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'currentUser',
      'cmsUsers'
    ])
  },
  created () {
    this.setSearchAssetType({ assetType: 'paper_trail_versions' })
    this.setSearchUrl({ url: railsRoutes.api_path({ type: 'searches' }) })

    var keys = [
      { key: 'obj_ids', display_name: 'Object Id', type: 'text' },
      { key: 'obj_types', display_name: 'Object Type', type: 'multiple_autocomplete', options: this.objectTypes.sort().map(x => { return { name: x } }), trackBy: 'name', label: 'name' },
      { key: 'events', display_name: 'Event', type: 'multiple_autocomplete', options: this.eventTypes.sort().map(x => { return { name: x } }), trackBy: 'name', label: 'name' },
      { key: 'created_at', display_name: 'Date', type: 'date_range' }
    ]

    this.setAssetType({ assetType: 'paper_trail_versions' })
    this.setSearchKeys({ keys: keys })

//    this.setAvailableKeys(keys)
  },
  methods: {
    ready () {
      return this.currentUser.apps.length !== 0
    },
    ...mapMutations([
      'setAssetType',
      'setSearchKeys',
      'resetSearchResults',
      'setCmsUsers',
      'setSearchAssetType',
      'setSearchUrl'
    ]),
    ...mapActions(['searching'])
  }

}
</script>
<style lang="scss">
.history-search {
  .title {
    margin: 1rem 0;
    font-size: 2.25rem;
    font-weight: 800;
  }
}
</style>
