<template>
  <div class="flex-table-row history-result-view">
    <div class="flex-table-cell result-id id">
      <div class="result-data-id result-data">
        {{ result.obj_id }}
      </div>
    </div>
    <div class="flex-table-cell object-type type">
      <div class="table-data-size fixed-section">
        <div class="type-section">
          <div class="type-content">
            <div class="type-element result-data">
              {{ result.obj_type }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-table-cell event">
      <div class="table-data-size fixed-section">
        <div class="event-section">
          <div class="event-content result-data">
            {{ result.event }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-table-cell result-diff">
      <div class="diff-section">
        <div
          v-if="Object.keys(result.diff).length > 0"
          class="flex-table asset-table diff-table"
        >
          <div class="flex-table-head asset-table-head">
            <div class="flex-header-section">
              <div class="flex-header">
                <span>Column</span>
              </div>
              <div class="flex-header">
                <span>Before</span>
              </div>
              <div class="flex-header">
                <span>After</span>
              </div>
            </div>
          </div>
          <div class="flex-table-body">
            <div
              v-for="(column, i) in Object.keys(result.diff)"
              :key="i"
              class="search_result flex-table-row"
              :column="column"
            >
              <div class="flex-table-cell">
                <div class="result-data">
                  {{ column }}
                </div>
              </div>
              <div class="flex-table-cell">
                <div v-if="column === 'updated_at' || column === 'created_at'">
                  <div class="result-data">
                    {{ prettyDate(result.diff[column][0]) }}
                  </div>
                </div>
                <div v-else>
                  <div class="result-data">
                    {{ result.diff[column][0] }}
                  </div>
                </div>
              </div>
              <div class="flex-table-cell">
                <div v-if="column === 'updated_at' || column === 'created_at'">
                  <div class="result-data">
                    {{ prettyDate(result.diff[column][1]) }}
                  </div>
                </div>
                <div v-else>
                  <div class="result-data">
                    {{ result.diff[column][1] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="no-results"
        >
          <div class="result-data">
            N/A
          </div>
        </div>
      </div>
    </div>
    <div class="flex-table-cell whodunit">
      <div class="result-data">
        <div class="table-data-size">
          {{ result.whodunnit }}
        </div>
      </div>
    </div>
    <div class="flex-table-cell history-date">
      <div class="result-data flex-product">
        <div class="table-data-size">
          {{ prettyDate(result.created_at) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import date from '../../mixins/date'

export default {
  mixins: [ date ],
  props: ['result']
}
</script>
